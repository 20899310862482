import React from 'react'
import Layout from '@/components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className='relative py-40 h-screen flex justify-center items-center'>
      <div className='error_bg'></div>

      <div className='container flex justify-center items-center text-center'>
        <div className='relative'>
          <h1>404</h1>
          <p>Can’t seem to find page you’re looking for </p>
          <Link to='/' className='btn btn__green mt-5'>
            Homepage
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
